import React, {Component} from "react"
import {Link} from "gatsby"
import Button from '@material-ui/core/Button'
import ArrowRightIcon from '@material-ui/icons/ArrowForwardIosRounded'
import CloseIcon from '@material-ui/icons/Close'
import TextField from "@material-ui/core/TextField";
import {isValidPhone} from "../common/utils/CommonUtils";
import "./popup-cta.scss"
import {BranchUtils} from "../common/utils/BranchUtils";

class TryItNowPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            phone: '',
            success: false,
            error: false
        }
    }

    sendPhone(phoneNumber) {
        if (!this.state.error) {
            const phone = isValidPhone(phoneNumber);
            if (phone) {

                let fromPage = 'try-it-now-popup';

                BranchUtils.desktopSendSMS(phone, fromPage, '', fromPage);

                this.setState({
                    success: true
                });
            } else {
                console.log("this.state.error", this.state.error);
                this.setState({
                    error: true,
                    success: false,
                    phone: ''
                });
            }
        }
    };

    render() {
        let currentPathName = typeof window !== 'undefined'
            ? window.location.pathname : '';
        currentPathName = currentPathName.replace('/', '');

        return (
            <div className="popup-cta-wrapper">
                <div className="single-pop-CTA">
                    <Button className="close-btn" onClick={this.props.onClose}
                            color="primary">
                        <Link onClick={() => {
                            if (this.props.onPressClose) {
                                console.log('Has access to state');
                                this.props.onPressClose();
                            } else {
                                console.log('No access to state');
                            }
                            setTimeout(() => {
                                if (typeof window !== 'undefined') {
                                    window.location.reload();
                                }

                            }, 50)

                        }} to={"/" + currentPathName + ""}><CloseIcon/></Link>
                    </Button>
                    <div className="white-box on-left">
                        <div className="inner-list-box">
                            <h2 className="visit-header"
                                style={{textAlign: 'center'}}>
                                Start getting
                                <span className="light">what you need</span>
                            </h2>
                            <a className="share-link"
                               href="https://confidanthealth.typeform.com/to/e04mW6"
                               target="_blank" rel="noopener noreferrer">
                                <div className="service-list">
                                    <div className="single-service">
                                        <img
                                            className="service-img"
                                            src={require(
                                                "../assets/images/service-video.svg")}
                                            alt="Icon"/>
                                        <div className="service-text">
                                            <p className="main-text">Telehealth
                                                Visit</p>
                                            <p className="sub-text">Behavioral
                                                Health</p>
                                        </div>
                                        <div className="next-arrow">
                                            <ArrowRightIcon/>
                                        </div>
                                    </div>

                                    <div className="single-service">
                                        <img
                                            className="service-img"
                                            src={require(
                                                "../assets/images/service-pills.svg")}
                                            alt="Icon"/>
                                        <div className="service-text">
                                            <p className="main-text">Reduce
                                                drinking</p>
                                            <p className="sub-text">Naloxone</p>
                                        </div>
                                        <div className="next-arrow">
                                            <ArrowRightIcon/>
                                        </div>
                                    </div>

                                    <div className="single-service">
                                        <img
                                            className="service-img"
                                            src={require(
                                                "../assets/images/service-pills.svg")}
                                            alt="Icon"/>
                                        <div className="service-text">
                                            <p className="main-text">Reduce
                                                Opioids</p>
                                            <p className="sub-text">Suboxone</p>
                                        </div>
                                        <div className="next-arrow">
                                            <ArrowRightIcon/>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="white-box on-right bg-img">
                        <div className="inner-phone-box">
                            <h2 className="visit-header"
                                style={{textAlign: 'center'}}>
                                We’re here
                                <span
                                    className="light">whenever you need us</span>
                            </h2>
                            <div className="signup-input-wrap">
                                <div className="input-wrap2">
                                    <TextField
                                        id="phone-input"
                                        className="phone-input"
                                        placeholder="+14132300085"
                                        value={this.state.phone}
                                        margin="normal"
                                        onChange={event => {
                                            const {value} = event.target;
                                            this.setState({
                                                phone: value,
                                                error: false,
                                                success: false
                                            });
                                        }}
                                    />
                                    <button
                                        className="blue-simple-btn"
                                        color="primary"
                                        onClick={() => {
                                            this.sendPhone(this.state.phone)
                                        }}>
                                        Get App
                                    </button>
                                </div>
                                {this.state.error ? <p
                                    className="error-msg">Please Enter Valid
                                    Phone</p> : null}
                                {this.state.success ? <p
                                    className="success-msg">Got it! Sending you a text with the link</p> : null}
                            </div>
                            <div className="store-btns">
                                <Button className="itune-btn" color="primary">
                                    <a target="_blank" rel="noopener noreferrer"
                                       href="https://apps.apple.com/us/app/confidant-health/id1478727129">
                                        <img src={require(
                                            '../assets/images/itunes.svg')}
                                             alt="itune"/>
                                    </a>
                                </Button>
                                <Button className="playstore-btn"
                                        color="primary">
                                    <a target="_blank" rel="noopener noreferrer"
                                       href="https://play.google.com/store/apps/details?id=live.safeopioidusecoalition.confidant&hl=en">
                                        <img src={require(
                                            '../assets/images/playstore.png')}
                                             alt="play"/>
                                    </a>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TryItNowPopup
